:root {
	font-size: 16px;
}

*, *::before, *::after {
	box-sizing: border-box;
}

html, body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}
